import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { login, logout } from "../store/reducers/userSlice";
import {
  getUserFromCache,
  setUserToCache,
  removeUserFromCache,
} from "../utils/localCache";
import sanitizeUserData from "../utils/sanitizeUserData";
import useUserData from "./useUserData";

const useAuth = () => {
  const dispatch = useDispatch();
  const { fetchUserProfile } = useUserData();
  const auth = getAuth();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkUserAuth = async (userAuth) => {
      if (userAuth) {
        try {
          // Fetch user profile data
          const userProfile = await fetchUserProfile(userAuth.uid);

          if (userProfile) {
            // Extract only serializable data
            const userData = {
              uid: userAuth.uid,
              email: userAuth.email,
              displayName: userAuth.displayName,
              photoURL: userAuth.photoURL,
              // Include any other data that you need, excluding non-serializable ones like stsTokenManager
            };
            // Sanitize user data to remove non-serializable values
            const sanitizedData = sanitizeUserData({
              ...userData,
              ...userProfile,
            });
            // Update local cache
            setUserToCache(sanitizedData);
            // Update Redux state
            dispatch(login(sanitizedData));
          } else {
            throw new Error("Failed to fetch user profile data");
          }
        } catch (error) {
          // No user is signed in, clear cache and Redux state
          removeUserFromCache();
          dispatch(logout());
        }
      } else {
        // No user is signed in, clear cache and Redux state
        removeUserFromCache();
        dispatch(logout());
      }
      setLoading(false);
    };

    // Check local cache first for user data
    const cachedUser = getUserFromCache();
    if (cachedUser) {
      dispatch(login(cachedUser));
      setLoading(false);
    } else {
      // Check at page load if a user is authenticated
      const unsubscribe = onAuthStateChanged(auth, checkUserAuth);

      return () => {
        // Clean up the subscription when the component unmounts
        unsubscribe();
      };
    }
  }, [dispatch, fetchUserProfile, auth]);

  return { loading };
};

export default useAuth;
