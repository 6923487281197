// src/pages/Results.js

import React, { useState, useEffect, useMemo, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import { fetchUserProfileData } from "../store/actions/userActions";
import Navbar from "../components/constants/Navbar";
import Footer from "../components/constants/Footer";
import OverallStatsGraph from "../components/charts/OverallStatsGraph";
import {
  Switch,
  RadioGroup,
  Radio,
  Label,
  Dialog,
  Transition,
  Description,
  TabGroup,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
} from "@headlessui/react";
import {
  FireIcon,
  ShieldCheckIcon,
  HeartIcon,
  SparklesIcon,
  ClockIcon,
  FaceSmileIcon,
  SunIcon,
  ScaleIcon,
  GiftIcon,
  GlobeAltIcon,
  CheckBadgeIcon,
  WrenchIcon,
  ChevronUpIcon,
  ChevronDownIcon,
} from "@heroicons/react/24/outline";
import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/20/solid";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const modIcons = {
  Faithful: SparklesIcon,
  Pure: ShieldCheckIcon,
  Loving: HeartIcon,
  Hopeful: FireIcon,
  Patient: ClockIcon,
  Meek: WrenchIcon,
  True: SunIcon,
  Wise: ScaleIcon,
  Grateful: GiftIcon,
  Joyous: FaceSmileIcon,
  Merciful: HeartIcon,
  Peaceful: GlobeAltIcon,
};

const modColors = {
  Faithful: "bg-faithful",
  Pure: "bg-pure-dark",
  Loving: "bg-loving",
  Hopeful: "bg-hopeful",
  Patient: "bg-patient",
  Meek: "bg-meek",
  True: "bg-true",
  Wise: "bg-wise-dark",
  Grateful: "bg-grateful",
  Joyous: "bg-joyous",
  Merciful: "bg-merciful",
  Peaceful: "bg-peaceful",
};

const Results = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const userProfileData = useSelector((state) => state.user.userProfileData);

  const [latestAnswers, setLatestAnswers] = useState([]);
  const [previousAnswers, setPreviousAnswers] = useState([]);
  const [sectionStats, setSectionStats] = useState([]);
  const [lowestCategories, setLowestCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedMoD, setSelectedMoD] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);

  const [historicalAnswers, setHistoricalAnswers] = useState([]);
  const [historicalSectionStats, setHistoricalSectionStats] = useState([]);
  const [timeRange, setTimeRange] = useState("all"); // 'week', 'month', 'all'

  const [compareToggle, setCompareToggle] = useState(false);
  const [sortConfig, setSortConfig] = useState({
    key: "score",
    direction: "ascending",
  });

  const navigate = useNavigate();

  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  console.log(
    "error",
    error,
    loading,
    message,
    setMessage,
    setSortConfig,
    setLoading,
    setPreviousAnswers
  );

  const fetchLatestAnswers = async () => {
    if (!user?.uid) {
      console.error("User not logged in.");
      return;
    }

    try {
      const attemptRes = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/attempt/${user.uid}/latest`
      );

      const latestAttempt = attemptRes.data;

      if (!latestAttempt) {
        setError("No attempts found for user.");
        return;
      }

      // Fetch answers for latest attempt
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/answers/${user.uid}/${latestAttempt._id}`
      );
      setLatestAnswers(res.data);
    } catch (error) {
      console.error("Error fetching user answers:", error);
      setError("Failed to fetch user answers.");
    }
  };

  const fetchHistoricalAnswers = async () => {
    if (!user?.uid) {
      console.error("User not logged in.");
      return;
    }

    try {
      // Fetch all answers for the user
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/answers/${user.uid}`
      );
      setHistoricalAnswers(res.data);
    } catch (error) {
      console.error("Error fetching historical answers:", error);
    }
  };

  useEffect(() => {
    if (user?.uid) {
      if (!userProfileData) {
        dispatch(fetchUserProfileData(user.uid));
      }
      fetchLatestAnswers();
      fetchHistoricalAnswers();
    }
  }, [user, userProfileData, dispatch]);

  useEffect(() => {
    if (latestAnswers.length > 0) {
      processAnswers(latestAnswers, previousAnswers);
    }
  }, [latestAnswers, previousAnswers]);

  useEffect(() => {
    if (historicalAnswers.length > 0) {
      const stats = processHistoricalAnswers(historicalAnswers, timeRange);
      setHistoricalSectionStats(stats);
    }
  }, [historicalAnswers, timeRange]);

  const processAnswers = (latestAnswers, previousAnswers) => {
    const scoresBySection = {};
    const previousScoresBySection = {};

    // Process latest answers
    latestAnswers.forEach((answer) => {
      const score = answer.score;
      const section = answer.question.mod;

      if (scoresBySection[section]) {
        scoresBySection[section].push(score);
      } else {
        scoresBySection[section] = [score];
      }
    });

    // Process previous answers
    previousAnswers.forEach((answer) => {
      const score = answer.score;
      const section = answer.question.mod;

      if (previousScoresBySection[section]) {
        previousScoresBySection[section].push(score);
      } else {
        previousScoresBySection[section] = [score];
      }
    });

    const sectionStats = Object.keys(scoresBySection).map((section) => {
      const sectionScores = scoresBySection[section];
      const average =
        sectionScores.reduce((a, b) => a + b, 0) / sectionScores.length || 0;
      const roundedAverage = parseFloat(average.toFixed(1));

      // Previous average
      const previousSectionScores = previousScoresBySection[section] || [];
      const previousAverage =
        previousSectionScores.reduce((a, b) => a + b, 0) /
          previousSectionScores.length || 0;
      const roundedPreviousAverage = parseFloat(previousAverage.toFixed(1));

      const change = roundedAverage - roundedPreviousAverage;

      return { section, average: roundedAverage, change };
    });

    setSectionStats(sectionStats);

    // Calculate lowest average score among sections
    const minAverage = Math.min(...sectionStats.map((s) => s.average));

    const lowestCategories = sectionStats
      .filter((s) => s.average === minAverage)
      .map((s) => s.section);

    setLowestCategories(lowestCategories);
  };

  const processHistoricalAnswers = (answers, timeRange) => {
    const now = new Date();
    let filteredAnswers = [];

    if (timeRange === "week") {
      const oneWeekAgo = new Date();
      oneWeekAgo.setDate(now.getDate() - 7);
      filteredAnswers = answers.filter(
        (answer) => new Date(answer.createdAt) >= oneWeekAgo
      );
    } else if (timeRange === "month") {
      const oneMonthAgo = new Date();
      oneMonthAgo.setMonth(now.getMonth() - 1);
      filteredAnswers = answers.filter(
        (answer) => new Date(answer.createdAt) >= oneMonthAgo
      );
    } else {
      // 'all' time
      filteredAnswers = answers;
    }

    // Calculate average scores by mod category
    const scoresBySection = {};

    filteredAnswers.forEach((answer) => {
      const score = answer.score;
      const section = answer.question.mod;

      if (scoresBySection[section]) {
        scoresBySection[section].push(score);
      } else {
        scoresBySection[section] = [score];
      }
    });

    const historicalSectionStats = Object.keys(scoresBySection).map(
      (section) => {
        const sectionScores = scoresBySection[section];
        const average =
          sectionScores.reduce((a, b) => a + b, 0) / sectionScores.length || 0;
        const roundedAverage = parseFloat(average.toFixed(1));

        return { section, average: roundedAverage };
      }
    );

    return historicalSectionStats;
  };

  const sortedQuestions = useMemo(() => {
    let sortableItems = [...latestAnswers];
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        if (sortConfig.key === "score") {
          if (a.score < b.score) {
            return sortConfig.direction === "ascending" ? -1 : 1;
          }
          if (a.score > b.score) {
            return sortConfig.direction === "ascending" ? 1 : -1;
          }
        } else if (sortConfig.key === "questionText") {
          if (a.question.qText < b.question.qText) {
            return sortConfig.direction === "ascending" ? -1 : 1;
          }
          if (a.question.qText > b.question.qText) {
            return sortConfig.direction === "ascending" ? 1 : -1;
          }
        } else if (sortConfig.key === "category") {
          if (a.question.mod < b.question.mod) {
            return sortConfig.direction === "ascending" ? -1 : 1;
          }
          if (a.question.mod > b.question.mod) {
            return sortConfig.direction === "ascending" ? 1 : -1;
          }
        }
        return 0;
      });
    }
    return sortableItems;
  }, [latestAnswers, sortConfig]);

  const requestSort = (key) => {
    let direction = "ascending";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const confirmFocus = async () => {
    try {
      // TODO: Update user focusMod in database

      if (tabIndex === 0) {
        // Focus on MoD
        /* await axios.put(
        `${process.env.REACT_APP_API_URL}/api/users/${user.uid}/focusMod`,
        {
          focusMod: selectedMoD,
        }
      ); */
        setIsModalOpen(false);
        navigate("/study-guide", { state: { mod: selectedMoD } });
      } else {
        // Focus on question
        /* await axios.put(
        `${process.env.REACT_APP_API_URL}/api/users/${user.uid}/focusQuestion`,
        {
          focusQuestion: selectedQuestion.question._id,
        }
      ); */
        setIsModalOpen(false);
        navigate("/study-guide", { state: { question: selectedQuestion } });
      }
    } catch (error) {
      console.error("Error setting focusMod:", error);
    }
  };

  return (
    <div>
      <Navbar />

      <div className="container max-w-screen-lg p-4 mx-auto my-6">
        <h1 className="mb-4 text-3xl font-bold text-center text-gray-900 md:text-4xl">
          Your Assessment Results
        </h1>

        {/* Compare Toggle */}
        <div className="flex items-center justify-center mb-6">
          <Switch
            checked={compareToggle}
            onChange={setCompareToggle}
            className={`${
              compareToggle ? "bg-primary-600" : "bg-gray-200"
            } relative inline-flex h-6 w-11 items-center rounded-full`}
          >
            <span className="sr-only">Compare with historical data</span>
            <span
              className={`${
                compareToggle ? "translate-x-6" : "translate-x-1"
              } inline-block h-4 w-4 transform rounded-full bg-white transition`}
            />
          </Switch>
          <span className="ml-3 text-sm text-gray-700">
            Compare with previous results
          </span>
        </div>

        {/* Time Range Selector */}
        <div className="flex justify-center mt-4">
          <label htmlFor="timeRange" className="mr-2 text-sm text-gray-700">
            Select Time Range:
          </label>
          <select
            id="timeRange"
            value={timeRange}
            onChange={(e) => setTimeRange(e.target.value)}
            className="p-2 border border-gray-300 rounded-md"
          >
            <option value="week">Previous Week</option>
            <option value="month">Previous Month</option>
            <option value="all">All Time</option>
          </select>
        </div>

        {/* Overall Stats */}
        <div>
          <OverallStatsGraph
            sectionStats={sectionStats}
            historicalSectionStats={compareToggle ? historicalSectionStats : []}
          />
        </div>

        {/* Tabs */}
        <TabGroup selectedIndex={tabIndex} onChange={setTabIndex}>
          <TabList className="flex p-1 mt-8 space-x-1 bg-blue-900/20 rounded-xl">
            <Tab
              className={({ selected }) =>
                classNames(
                  "w-full py-2.5 text-sm leading-5 font-medium text-blue-700 rounded-lg",
                  selected
                    ? "bg-white shadow"
                    : "text-blue-100 hover:bg-white/[0.12] hover:text-white"
                )
              }
            >
              Categories
            </Tab>
            <Tab
              className={({ selected }) =>
                classNames(
                  "w-full py-2.5 text-sm leading-5 font-medium text-blue-700 rounded-lg",
                  selected
                    ? "bg-white shadow"
                    : "text-blue-100 hover:bg-white/[0.12] hover:text-white"
                )
              }
            >
              Questions
            </Tab>
          </TabList>
          <TabPanels className="mt-2">
            {/* Category Tab */}
            <TabPanel>
              {/* Choose Your Focus */}
              <div className="px-6 py-24 sm:py-32 lg:px-8">
                <div className="max-w-2xl mx-auto text-center">
                  <h1 className="text-4xl font-semibold tracking-tight text-gray-900 sm:text-6xl">
                    Choose Your Focus
                  </h1>
                  <p className="mt-8 text-lg font-medium text-gray-500 text-pretty sm:text-xl/8">
                    Based on your assessment results, choose a Mark of
                    Discipleship you would like to focus on improving.
                  </p>

                  <p className="mt-8 text-lg font-medium text-gray-500 text-pretty sm:text-xl/8">
                    Focusing on your weakest areas can lead to significant and
                    quick changes in your spiritual growth.
                  </p>
                </div>

                {/* MoD Cards */}
                <div className="mt-12">
                  <h3 className="font-semibold text-gray-900 text-sm/6">
                    Avg. Scores by Category
                  </h3>
                  <RadioGroup value={selectedMoD} onChange={setSelectedMoD}>
                    <Label className="sr-only">
                      Select a Mark of Discipleship
                    </Label>
                    <div className="grid grid-cols-1 gap-5 mt-5 sm:grid-cols-2 lg:grid-cols-3">
                      {sectionStats.map((item) => {
                        const Icon = modIcons[item.section];
                        const bgColor = modColors[item.section];
                        return (
                          <Radio
                            key={item.section}
                            value={item.section}
                            className={({ checked }) =>
                              `${
                                checked
                                  ? "border-transparent ring-2 ring-offset-2 ring-primary-500"
                                  : "border-gray-300"
                              } ${
                                lowestCategories.includes(item.section)
                                  ? "bg-red-50"
                                  : "bg-white"
                              } relative flex flex-col overflow-hidden rounded-lg border px-4 pb-4 pt-5 shadow cursor-pointer focus:outline-none sm:px-6 sm:pt-6`
                            }
                          >
                            <div className="absolute top-0 right-0 p-2">
                              {lowestCategories.includes(item.section) && (
                                <div className="inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                                  Lowest Score
                                </div>
                              )}
                            </div>
                            <div className="flex items-center">
                              <div
                                className={`absolute rounded-md p-3 ${bgColor}`}
                              >
                                <Icon
                                  className="w-6 h-6 text-white"
                                  aria-hidden="true"
                                />
                              </div>

                              <div className="ml-16">
                                <Label
                                  as="p"
                                  className="text-sm font-medium text-gray-900 truncate"
                                >
                                  {item.section}
                                </Label>
                                <Description
                                  as="div"
                                  className="flex items-baseline pb-6 sm:pb-7"
                                >
                                  <p className="text-2xl font-semibold text-gray-900">
                                    {item.average}
                                  </p>

                                  {item.change > 0 ? (
                                    <ArrowUpIcon
                                      className="self-center flex-shrink-0 w-5 h-5 text-green-500"
                                      aria-hidden="true"
                                    />
                                  ) : (
                                    <ArrowDownIcon
                                      className="self-center flex-shrink-0 w-5 h-5 text-red-500"
                                      aria-hidden="true"
                                    />
                                  )}
                                  <span className="sr-only">
                                    {" "}
                                    {item.change > 0
                                      ? "Increased"
                                      : "Decreased"}{" "}
                                    by{" "}
                                  </span>
                                  {item.change > 0 ? (
                                    <p
                                      className="self-center flex-shrink-0 w-5 h-5 text-green-500"
                                      aria-hidden="true"
                                    >
                                      {item.change}
                                    </p>
                                  ) : (
                                    <p
                                      className="self-center flex-shrink-0 w-5 h-5 text-red-500"
                                      aria-hidden="true"
                                    >
                                      {item.change}
                                    </p>
                                  )}
                                </Description>
                              </div>
                            </div>
                          </Radio>
                        );
                      })}
                    </div>
                  </RadioGroup>

                  {/* Set Focus Button */}
                  <div className="mt-6">
                    <button
                      onClick={() => setIsModalOpen(true)}
                      disabled={!selectedMoD}
                      className={`${
                        selectedMoD
                          ? "bg-green-600 hover:bg-green-700"
                          : "bg-gray-400 cursor-not-allowed"
                      } px-4 py-2 text-white rounded-md`}
                    >
                      Set Focus
                    </button>
                  </div>
                </div>
              </div>
            </TabPanel>

            {/* Questions Tab */}
            <TabPanel>
              <div className="mt-8 text-center">
                <h1 className="mb-4 text-4xl font-extrabold leading-none tracking-tight text-gray-900">
                  Choose a Question to Focus On
                </h1>

                {/* Questions Table */}
                <div className="mt-6">
                  <div className="flex justify-end mb-2">
                    <button
                      onClick={() => requestSort("questionText")}
                      className="px-3 py-1 text-sm text-gray-700 bg-gray-200 rounded"
                    >
                      Sort by Question
                    </button>
                    <button
                      onClick={() => requestSort("category")}
                      className="px-3 py-1 ml-2 text-sm text-gray-700 bg-gray-200 rounded"
                    >
                      Sort by Category
                    </button>
                    <button
                      onClick={() => requestSort("score")}
                      className="px-3 py-1 ml-2 text-sm text-gray-700 bg-gray-200 rounded"
                    >
                      Sort by Score
                    </button>
                  </div>
                  <div className="overflow-hidden bg-white border border-gray-200 rounded-lg shadow">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            className="px-6 py-3 text-sm font-medium tracking-wider text-left text-gray-500 uppercase cursor-pointer"
                            onClick={() => requestSort("questionText")}
                          >
                            Question
                            {sortConfig.key === "questionText" &&
                              (sortConfig.direction === "ascending" ? (
                                <ChevronUpIcon className="inline w-4 h-4" />
                              ) : (
                                <ChevronDownIcon className="inline w-4 h-4" />
                              ))}
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-sm font-medium tracking-wider text-left text-gray-500 uppercase cursor-pointer"
                            onClick={() => requestSort("category")}
                          >
                            Category
                            {sortConfig.key === "category" &&
                              (sortConfig.direction === "ascending" ? (
                                <ChevronUpIcon className="inline w-4 h-4" />
                              ) : (
                                <ChevronDownIcon className="inline w-4 h-4" />
                              ))}
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-sm font-medium tracking-wider text-left text-gray-500 uppercase cursor-pointer"
                            onClick={() => requestSort("score")}
                          >
                            Score
                            {sortConfig.key === "score" &&
                              (sortConfig.direction === "ascending" ? (
                                <ChevronUpIcon className="inline w-4 h-4" />
                              ) : (
                                <ChevronDownIcon className="inline w-4 h-4" />
                              ))}
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                        {sortedQuestions.map((answer) => (
                          <tr
                            key={answer.question._id}
                            className={`${
                              lowestCategories.includes(answer.question.mod)
                                ? "bg-red-50"
                                : ""
                            } ${
                              selectedQuestion &&
                              selectedQuestion.question._id ===
                                answer.question._id
                                ? "bg-blue-100"
                                : ""
                            } hover:bg-gray-100 cursor-pointer`}
                            onClick={() => setSelectedQuestion(answer)}
                          >
                            <td className="px-6 py-4 text-sm text-gray-700">
                              {answer.question.qText}
                            </td>
                            <td className="px-6 py-4 text-sm text-gray-700">
                              {answer.question.mod}
                            </td>
                            <td className="px-6 py-4 text-sm text-gray-700">
                              {answer.score}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  {/* Set Focus Button */}
                  <div className="mt-6">
                    <button
                      onClick={() => setIsModalOpen(true)}
                      disabled={!selectedQuestion}
                      className={`${
                        selectedQuestion
                          ? "bg-green-600 hover:bg-green-700"
                          : "bg-gray-400 cursor-not-allowed"
                      } px-4 py-2 text-white rounded-md`}
                    >
                      Set Focus
                    </button>
                  </div>
                </div>
              </div>
            </TabPanel>
          </TabPanels>
        </TabGroup>
      </div>

      {/* Confirmation Modal */}
      <Transition.Root show={isModalOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setIsModalOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div>
                    <div className="flex items-center justify-center w-12 h-12 mx-auto bg-blue-100 rounded-full">
                      <CheckBadgeIcon
                        className="w-6 h-6 text-blue-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-gray-900"
                      >
                        Confirm Your Focus
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Are you sure you want to set your focus to{" "}
                          <strong>{selectedMoD}</strong>?
                        </p>
                        <p className="mt-2 text-sm text-gray-500">
                          Focusing on areas needing improvement can lead to
                          significant growth. Remember the words of the prophets
                          and the scriptures as you embark on this journey.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      className="inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white bg-green-600 border border-transparent rounded-md shadow-sm hover:bg-green-700 sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={confirmFocus}
                    >
                      Confirm
                    </button>
                    <button
                      type="button"
                      className="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 sm:mt-0 sm:w-auto sm:text-sm"
                      onClick={() => setIsModalOpen(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <Footer />
    </div>
  );
};

export default Results;
