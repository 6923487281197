// src/pages/Main.js

import React from "react";
import Navbar from "../components/constants/Navbar";
import Hero from "../components/Hero";
import Footer from "../components/constants/Footer";
import Features from "../components/Features";

const Main = () => {
  return (
    <>
      <Navbar />
        <Hero />
        <Features />
      <Footer />
    </>
  );
};

export default Main;
