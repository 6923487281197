import { useState } from "react";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";

export default function InstructionsModal({ stage, onClose }) {
  // Accept stage and onClose as props
  const [page, setPage] = useState(1);

  const pages =
    stage === "start"
      ? [
          {
            image:
              "https://firebasestorage.googleapis.com/v0/b/christlike-attributes-c0fd3.appspot.com/o/discipleship-official%2Flikert.png?alt=media&token=ff206ab2-d99d-45f7-be1b-8fe9df9e749f",
            title: "Ready to Begin?",
            content:
              'Rate the following statements on how often they apply to you from "never" to "always".',
            color: "bg-[#CEEBF8]",
            button: "Next",
          },
          {
            image:
              "https://firebasestorage.googleapis.com/v0/b/christlike-attributes-c0fd3.appspot.com/o/discipleship-official%2Fprayer.png?alt=media&token=b5c60e3c-dcd1-4861-b977-ca171bfb1562",
            title: "Start With a Prayer",
            content: "Invite the Spirit to help you answer accurately.",
            color: "bg-[#FAFAC7]",
            button: "Ready!",
          },
        ]
      : [
          {
            image:
              "https://firebasestorage.googleapis.com/v0/b/christlike-attributes-c0fd3.appspot.com/o/discipleship-official%2Freflect.png?alt=media&token=de3da453-dea4-47c3-b113-22b90c040401",
            title: "Your Results",
            content:
              "There are lots of ways to read your results. Again, invite the Spirit to help you focus on what will help you most.",
            color: "bg-[#DFF4A2]",
            button: "Go!",
          },
        ];

  const nextPage = () => setPage(page + 1);
  const prevPage = () => setPage(page - 1);

  return (
    <Dialog
      open={true}
      onClose={onClose}
      className="fixed inset-0 z-50 overflow-y-auto"
    >
      {" "}
      <div className="flex items-center justify-center min-h-screen px-4 text-center">
        <DialogBackdrop className="fixed inset-0 bg-black bg-opacity-35" />

        <div className="fixed inset-0 z-10 flex items-center justify-center p-4">
          <DialogPanel className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
            <div className={`p-4 ${pages[page - 1].color} rounded-t-2xl`}>
              <img
                src={pages[page - 1].image}
                alt={pages[page - 1].title}
                className="w-full h-auto rounded-md"
              />

              <DialogTitle className="mt-4 text-2xl font-bold text-center text-gray-900">
                {pages[page - 1].title}
              </DialogTitle>
              <p className="mt-2 text-center text-gray-700">
                {pages[page - 1].content}
              </p>
            </div>

            <div className="flex justify-between mt-6">
              {page > 1 ? (
                <button
                  onClick={prevPage}
                  className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 hover:text-gray-800"
                >
                  Previous
                </button>
              ) : (
                <div />
              )}
              {page < pages.length ? (
                <button
                  onClick={nextPage}
                  className="items-end justify-end px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-primary hover:bg-primary-600"
                >
                  {pages[page - 1].button}
                </button>
              ) : (
                <button
                  onClick={onClose}
                  className="px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-primary hover:bg-primary-700"
                >
                  {pages[page - 1].button}
                </button>
              )}
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}
