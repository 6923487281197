import React, { useState } from "react";

const ScriptureComponent = ({ reference, content }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="pb-2 border-b border-gray-300">
      <button
        className="text-lg text-blue-500 hover:underline"
        onClick={() => setIsOpen(!isOpen)}
      >
        {reference}
      </button>
      {isOpen && <p className="mt-2 text-gray-700">{content}</p>}
    </div>
  );
};

export default ScriptureComponent;
