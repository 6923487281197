// src/store/reducers/userSlice.js
import { createSlice } from "@reduxjs/toolkit";
import {
  fetchUserProfileData,
  fetchUserAssessmentData,
  updateUserProfile,
  updateUserProfilePhoto,
} from "../actions/userActions";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    user: null,
    profileData: null,
    assessmentData: null,
  },
  reducers: {
    login: (state, action) => {
      state.user = action.payload;
    },
    logout: (state) => {
      state.user = null;
      state.profileData = null;
      state.assessmentData = null;
    },
    updateUser: (state, action) => {
      state.user = {
        ...state.user,
        email: action.payload.email,
        displayName: action.payload.displayName,
        photoURL: action.payload.photoURL,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserProfileData.fulfilled, (state, action) => {
        state.profileData = action.payload; // Store fetched profile data
      })
      .addCase(fetchUserAssessmentData.fulfilled, (state, action) => {
        state.assessmentData = action.payload; // Store fetched assessment data
      })
      .addCase(updateUserProfile.fulfilled, (state, action) => {
        state.user = { ...state.user, ...action.payload }; // Update user profile
      })
      .addCase(updateUserProfilePhoto.fulfilled, (state, action) => {
        state.user.photoURL = action.payload.photoURL; // Update user photo
      });
  },
});

export const { login, logout, updateUser } = userSlice.actions;

// selectors
export const selectUser = (state) => state.user.user;
export default userSlice.reducer;
