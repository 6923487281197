import React from "react";
import { RouterProvider } from "react-router-dom";
import router from "./router";
import useAuth from "./hooks/useAuth";

const Main = React.lazy(() => import("./pages/Main"));

function App() {
  const { loading: authLoading } = useAuth();

  if (authLoading) {
    return <div>Loading...</div>;
  }

  return (
    <React.Suspense fallback={<div>Loading...</div>}>
      <RouterProvider router={router}>
        <Main />
      </RouterProvider>
    </React.Suspense>
  );
}

export default App;
