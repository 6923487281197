/* 

The store is the global state container for the application. It holds the state of the application.
There is only one way to change the STATE inside the store, which is by dispatching an action.

getState()
It returns the current state tree of the application. It is equal to the last value returned by the store's reducer.

dispatch(action)
It is the only way to trigger a state change. The store's reducer function will be called with the current state and the given action.
Its return value will be considered the next state.

A "reducing function" or "reducer," is a function that accepts an accumulation and a value and returns a new accumulation.
They are used to reduce a collection of values down to a single value. In our case the returning value is the new state object.

A "slice" is a collection of Redux reducer logic and actions for a single feature in your app.

*/

//src/app/store.js
import { configureStore } from '@reduxjs/toolkit';
import userReducer from './reducers/userSlice';

export default configureStore({
  reducer: {
    user: userReducer,
  },
});
