import React from "react";
import { Link } from "react-router-dom";
import TempleIcon from "../assets/TempleIcon";

// Profile Info Card Component
const ProfileInfoCard = ({ user }) => {
  return (
    <div className="max-w-4xl p-6 mx-auto mt-6 bg-white rounded-lg shadow-lg">
      {/* User Display Name or Email */}
      <h2 className="flex items-center justify-center mb-6 text-3xl font-bold text-center text-gray-800">
        {user.displayName || user.email}

        {/* Conditionally render the TempleIcon if user is a member */}
        {user.latterDaySaint && (
          <div className="w-8 h-8 p-1.5 ml-4 rounded-full bg-faithful">
            <TempleIcon />
          </div>
        )}
      </h2>

      {/* Profile Picture and Info */}
      <div className="flex flex-col items-center md:flex-row md:items-start md:justify-center">
        {/* Profile Picture */}
        <div className="relative inline-block mb-6 mr-0 md:mr-6 md:mb-0">
          <img
            src={user.photoURL || "https://via.placeholder.com/150"}
            alt="User Avatar"
            className="object-cover w-32 h-32 rounded-full"
          />
        </div>

        {/* Display Name and Email */}
        <div className="w-full max-w-md">
          <div className="mb-4">
            <label className="block mb-2 text-sm font-bold text-gray-700">
              Email
            </label>
            <p className="text-gray-800">{user.email || "N/A"}</p>
          </div>

          {/* Edit Profile Button */}
          <Link
            to="/settings"
            className="block w-full px-4 py-2 text-center text-white transition duration-150 border border-transparent rounded-lg bg-meek-dark hover:bg-meek-light hover:border-gray-400 hover:shadow-lg"
          >
            Edit Profile
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ProfileInfoCard;
