import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Navbar from "../components/constants/Navbar";
import Footer from "../components/constants/Footer";
import ProfileInfoCard from "../components/ProfileInfoCard";
import {
  fetchUserProfileData,
  updateUserProfile,
} from "../store/actions/userActions";
import { FaMedal, FaCertificate } from "react-icons/fa";

const Profile = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const userProfileData = useSelector((state) => state.user.userProfileData);

  const [editing, setEditing] = useState(false);
  const [profileData, setProfileData] = useState({
    email: user?.email || "",
    displayName: user?.displayName || "",
    photoURL: user?.photoURL || "",
    testimony: userProfileData?.testimony || "",
    spiritualStage: userProfileData?.spiritualStage || "Seeking",
    badges: userProfileData?.badges || [],
    achievements: userProfileData?.achievements || [],
  });

  // Fetch User Profile Data
  useEffect(() => {
    if (user?.uid && !userProfileData) {
      dispatch(fetchUserProfileData(user.uid));
    }
  }, [user, userProfileData, dispatch]);

  useEffect(() => {
    if (userProfileData) {
      setProfileData((prev) => ({
        ...prev,
        testimony: userProfileData.testimony || "",
        spiritualStage: userProfileData.spiritualStage || "Seeking",
        badges: userProfileData.badges || [],
        achievements: userProfileData.achievements || [],
      }));
    }
  }, [userProfileData]);

  const handleProfileSave = () => {
    dispatch(updateUserProfile(profileData)); // Using updateUserProfile here
    setEditing(false);
  };

  const handleChange = (e) => {
    setProfileData({ ...profileData, [e.target.name]: e.target.value });
  };

  const spiritualProgress = {
    Seeking: 25,
    Growing: 50,
    Strengthening: 75,
    Flourishing: 100,
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <Navbar />
      <div className="container py-8 mx-auto">
        {/* Profile Section */}
        <ProfileInfoCard
          user={user}
          profileData={profileData}
          fetchUserProfile={(uid) => dispatch(fetchUserProfileData(uid))}
          setProfileData={setProfileData}
          editing={editing}
          setEditing={setEditing}
          handleProfileSave={handleProfileSave}
          handleChange={handleChange}
        />

        {/* Progress Section */}
        <div className="mt-8">
          <h3 className="mb-4 text-lg font-semibold">Spiritual Progress</h3>
          <div className="w-full bg-gray-200 rounded-full">
            <div
              className="p-1 text-xs font-medium leading-none text-center text-green-100 bg-green-500 rounded-full"
              style={{
                width: `${spiritualProgress[profileData.spiritualStage]}%`,
              }}
            >
              {spiritualProgress[profileData.spiritualStage]}%
            </div>
          </div>
        </div>

        {/* Badges Section */}
        <div className="mt-8">
          <h3 className="mb-4 text-lg font-semibold">Badges Earned</h3>
          <div className="grid grid-cols-2 gap-4 md:grid-cols-4">
            {profileData.badges.length > 0 ? (
              profileData.badges.map((badge, index) => (
                <div
                  key={index}
                  className="flex items-center justify-center p-4 bg-blue-100 rounded-lg"
                >
                  <FaMedal className="text-3xl text-blue-600" />
                  <span className="ml-2 text-lg">{badge}</span>
                </div>
              ))
            ) : (
              <p>No badges earned yet.</p>
            )}
          </div>
        </div>

        {/* Achievements Section */}
        <div className="mt-8">
          <h3 className="mb-4 text-lg font-semibold">Achievements</h3>
          <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
            {profileData.achievements.length > 0 ? (
              profileData.achievements.map((achievement, index) => (
                <div
                  key={index}
                  className="flex items-center justify-center p-4 bg-green-100 rounded-lg"
                >
                  <FaCertificate className="text-3xl text-green-600" />
                  <span className="ml-2 text-lg">{achievement}</span>
                </div>
              ))
            ) : (
              <p>No achievements yet.</p>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Profile;
