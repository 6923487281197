import React from "react";
import { Link } from "react-router-dom";
import { ChevronRightIcon } from "@heroicons/react/20/solid";

export default function Hero() {
  return (
    <div>
      <div className="relative mt-12 isolate">
        <div className="px-6 py-12 mx-auto max-w-7xl sm:py-32 lg:flex lg:items-center lg:gap-x-10 lg:px-8 lg:py-12">
          <div className="max-w-2xl mx-auto lg:mx-0 lg:flex-auto">
            <div className="flex">
              <div className="relative flex items-center px-4 py-1 text-gray-600 bg-white rounded-full gap-x-4 text-sm/6 ring-1 ring-gray-900/10 hover:ring-gray-900/20">
                <span className="font-semibold text-primary-600">Donate</span>
                <span aria-hidden="true" className="w-px h-4 bg-gray-900/10" />
                <Link to="/about" className="flex items-center gap-x-1">
                  <span aria-hidden="true" className="absolute inset-0" />
                  Contribute to the mission
                  <ChevronRightIcon
                    aria-hidden="true"
                    className="w-5 h-5 -mr-2 text-gray-400"
                  />
                </Link>
              </div>
            </div>
            <div className="text-5xl font-semibold tracking-tight text-gray-900 text-pretty sm:text-7xl">
              <h1 className="mt-10">
                Organize your efforts to become as{" "}
                <span className="text-primary">He is</span>.
              </h1>
            </div>

            <div className="flex items-center mt-10 gap-x-6">
              <Link
                to="/self-assessment"
                className="rounded-md bg-primary px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-primary-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
              >
                Get started
              </Link>
              <Link
                to="/learn-more"
                className="font-semibold text-gray-900 text-sm/6"
              >
                Learn more <span aria-hidden="true">→</span>
              </Link>
            </div>
          </div>
          <div className="mt-16 sm:mt-24 lg:mt-0 lg:flex-shrink-0 lg:flex-grow">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/christlike-attributes-c0fd3.appspot.com/o/discipleship-official%2Ffrolicking.png?alt=media&token=6f0a5af1-cfc6-4e58-aed1-1eb8c85d256a"
              alt="Disciplesheep frolicking"
              className="max-w-full mx-auto lg:w-full drop-shadow-xl"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
