// src/components/navigation/Navbar.js

import React, { useState } from "react";
import AuthModal from "../modals/AuthModal";
import { useSelector } from "react-redux";
import { auth } from "../../firebase/firebase";
import { Link } from "react-router-dom";

import { PhoneIcon, PlayCircleIcon } from "@heroicons/react/20/solid";
import {
  ArrowPathIcon,
  ChartPieIcon,
  CursorArrowRaysIcon,
  FingerPrintIcon,
  SquaresPlusIcon,
} from "@heroicons/react/24/outline";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";

import Flyout from "../navigation/Flyout";
import MobileMenu from "../navigation/MobileMenu";

const Navbar = () => {
  const user = useSelector((state) => state.user.user);
  const [showModal, setShowModal] = useState(false);
  console.log(user);

  /* Function to handle log out */
  const handleLogOut = () => {
    auth
      .signOut()
      .then(() => {
        console.log("User signed out.");
      })
      .catch((error) => {
        console.error("Error signing out:", error);
      });
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const openModal = () => {
    setShowModal(true);
  };

  const menuItems = [
    {
      name: "Self-Assessment",
      description: "Take the test to measure your spiritual growth",
      href: "/self-assessment",
      icon: ChartPieIcon,
      new: true,
      comingSoon: false,
      disabled: false,
    },
    {
      name: "What Lack I Yet",
      description: "Spiritual Checklist to measure your progress",
      href: "/what-lack-i-yet",
      icon: CursorArrowRaysIcon,
      comingSoon: true,
      disabled: true,
    },
    {
      name: "Study Plans",
      description: "Personalized study plans to help you grow",
      href: "#",
      icon: FingerPrintIcon,
      comingSoon: true,
      disabled: true,
    },
    {
      name: "Global Discipleship",
      description: "View & compare insights from all users",
      href: "#",
      icon: SquaresPlusIcon,
      comingSoon: true,
      disabled: true,
    },
  ];

  const profileItems = [
    { name: "Profile", href: "/profile", icon: PlayCircleIcon },
    { name: "My Stats", href: "/results", icon: PhoneIcon },
    { name: "Account Settings", href: "/settings", icon: PhoneIcon },
  ];

  const primaryMenuItems = [
    {
      name: "Self-Assessment",
      href: "/self-assessment",
      icon: ChartPieIcon,
      new: true,
      comingSoon: false,
    },
    {
      name: "What Lack I Yet?",
      href: "/commandments",
      icon: CursorArrowRaysIcon,
      new: false,
      comingSoon: true,
    },
    {
      name: "Study Plans",
      href: "/study-plans",
      icon: FingerPrintIcon,
      new: false,
      comingSoon: true,
    },
    {
      name: "Global Discipleship",
      href: "/global-discipleship",
      icon: SquaresPlusIcon,
      new: false,
      comingSoon: true,
    },
  ];

  const secondaryMenuItems = [
    { name: "About", href: "/about", icon: ArrowPathIcon },
    { name: "Contact", href: "/contact", icon: ArrowPathIcon },
  ];

  return (
    <header>
      <nav
        className="flex items-center justify-between p-4 mx-auto max-w-7xl lg:px-8"
        aria-label="Global"
      >
        {/* Left section with Logo and Site Name */}
        <div className="flex items-center space-x-6">
          {/* Logo */}
          <Link to="/" className="flex items-center space-x-2">
            <img
              className="w-auto h-10"
              src="https://firebasestorage.googleapis.com/v0/b/christlike-attributes-c0fd3.appspot.com/o/discipleship-official%2Fdisciplesheep-nobg.png?alt=media&token=dedeaedd-b5b9-495e-9802-7dffd423c9d1"
              alt="Discipleship Logo"
            />
            <h1 className="text-2xl font-bold text-gray-900">Discipleship</h1>
          </Link>

          {/* Navigation Links */}
          <div className="hidden lg:flex lg:space-x-8">
            <Flyout
              menuItems={menuItems}
              profileItems={profileItems}
              user={user}
              openModal={openModal}
            />
            {/* Additional navigation items */}
            <Link
              to="/about"
              className="inline-flex items-center text-sm font-semibold leading-6 text-gray-900 gap-x-1"
            >
              About
            </Link>
            <Link
              to="/contact"
              className="inline-flex items-center text-sm font-semibold leading-6 text-gray-900 gap-x-1"
            >
              Contact
            </Link>
          </div>
        </div>

        {/* Right section with CTA and Auth Buttons */}
        <div className="hidden lg:flex lg:items-center lg:space-x-4">
          {/* CTA Button */}
          <Link
            to="/self-assessment"
            className="px-4 py-2 font-semibold text-white transition-colors rounded-3xl bg-gradient-to-r from-goldenrod-400 to-salmon-500 hover:from-goldenrod-500 hover:to-salmon-600"
          >
            Take the Test
          </Link>

          {/* User Auth */}
          {user ? (
            <div className="flex items-center space-x-4">
              {/* Profile dropdown */}
              <Menu as="div" className="relative ml-3">
                <div>
                  <MenuButton className="relative flex text-sm bg-white rounded-full focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2">
                    <span className="absolute -inset-1.5" />
                    <span className="sr-only">Open user menu</span>
                    <img
                      alt=""
                      src={
                        user.photoURL ||
                        "https://firebasestorage.googleapis.com/v0/b/christlike-attributes-c0fd3.appspot.com/o/profilePictures%2Fprofile.png?alt=media&token=00ad0577-b2e7-4e94-ab0b-ac217a14a0d1"
                      }
                      className="w-8 h-8 rounded-full"
                    />
                  </MenuButton>
                </div>
                <MenuItems
                  transition
                  className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                >
                  <MenuItem>
                    <Link
                      to="/profile"
                      className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100"
                    >
                      Your Profile
                    </Link>
                  </MenuItem>
                  <MenuItem>
                    <Link
                      to="/results"
                      className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100"
                    >
                      Your Stats
                    </Link>
                  </MenuItem>
                  <MenuItem>
                    <Link
                      to="/settings"
                      className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100"
                    >
                      Settings
                    </Link>
                  </MenuItem>
                  <MenuItem>
                    <button
                      className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 w-full text-left"
                      onClick={handleLogOut}
                      aria-label="Log Out"
                    >
                      Log Out
                    </button>
                  </MenuItem>
                </MenuItems>
              </Menu>
            </div>
          ) : (
            <button
              className="font-semibold transition-colors text-goldenrod-600 hover:text-goldenrod-800"
              onClick={openModal}
              aria-label="Log In"
            >
              Log In
            </button>
          )}
        </div>

        {/* Mobile Menu */}
        <div className="lg:hidden">
          <MobileMenu
            primaryMenuItems={primaryMenuItems}
            secondaryMenuItems={secondaryMenuItems}
            profileItems={profileItems}
            user={user}
            openModal={openModal}
            handleLogOut={handleLogOut}
          />
        </div>
      </nav>

      {/* Login Modal */}
      {showModal && <AuthModal onClose={closeModal} />}
    </header>
  );
};

export default Navbar;
