import React from 'react';

const ExerciseComponent = ({ description }) => {
  return (
    <div className="p-4 bg-gray-100 rounded-md">
      <p>{description}</p>
    </div>
  );
};

export default ExerciseComponent;
