import React from "react";
import ScriptureComponent from "./ScriptureComponent";
import DiscourseComponent from "./DiscourseComponent";
import ExerciseComponent from "./ExerciseComponent";

const StudyGuideComponent = ({
  title,
  scriptures = [],
  discourses = [],
  exercises = [],
  question,
}) => {
  return (
    <div className="max-w-4xl p-6 mx-auto text-black bg-white rounded-lg shadow-md">
      <h1 className="mb-6 text-3xl font-bold text-center">{title}</h1>

      {/* Display the question text and other details */}
      <section className="mb-6">
        <h2 className="mb-2 text-xl font-semibold">Question</h2>
        <p className="mb-2 text-lg font-semibold">{question.qText}</p>
      </section>

      <section className="mb-6">
        <h2 className="mb-2 text-xl font-semibold">Scriptures</h2>
        <div className="space-y-4">
          {scriptures.map((scripture, index) => (
            <ScriptureComponent
              key={index}
              reference={scripture.reference}
              content={scripture.content}
            />
          ))}
        </div>
      </section>

      <section className="mb-6">
        <h2 className="mb-2 text-xl font-semibold">Discourses</h2>
        <div className="space-y-4">
          {discourses.map((discourse, index) => (
            <DiscourseComponent
              key={index}
              title={discourse.title}
              speakerTitle={discourse.speakerTitle}
              speakerName={discourse.speakerName}
              speakerCalling={discourse.speakerCalling}
              source={discourse.source}
              excerpt={discourse.excerpt}
            />
          ))}
        </div>
      </section>

      <section className="mb-6">
        <h2 className="mb-2 text-xl font-semibold">Exercises</h2>
        <div className="space-y-4">
          {exercises.map((exercise, index) => (
            <ExerciseComponent key={index} description={exercise.description} />
          ))}
        </div>
      </section>
    </div>
  );
};

export default StudyGuideComponent;
