import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { login } from "../store/reducers/userSlice";

// In-memory cache for user profiles
const userProfileCache = {};

const fetchUserProfile = async (uid) => {
  // Check if the user profile is in local memory cache
  if (userProfileCache[uid]) {
    console.log("2. User data not in Redux, checking cache...");
    return userProfileCache[uid];
  }

  // If not in cache, query MongoDB via API call
  try {
    console.log("3. User data not in cache, fetching from MongoDB...");
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/users/${uid}`
    );
    const userProfile = response.data;

    // Store the user profile in both the cache and Redux
    userProfileCache[uid] = userProfile;
    return userProfile;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      console.error("User profile not found in MongoDB (404).");
      return null;
    } else {
      console.error("Error fetching user profile:", error);
      throw error;
    }
  }
};

const useUserData = () => {
  const dispatch = useDispatch();
  const userFromRedux = useSelector((state) => state.user.user); // Pulling from Redux

  const getUserProfile = async (uid) => {
    // 1. Check if user profile exists in Redux store
    if (userFromRedux) {
      console.log("User data found in Redux, updating cache...");
      userProfileCache[uid] = userFromRedux; // Update cache with Redux data
      return userFromRedux;
    }

    // 2. If not found in Redux, check cache and MongoDB via fetchUserProfile
    const userProfile = await fetchUserProfile(uid);

    // 3. Update Redux with fetched user profile
    dispatch(login(userProfile)); // Updating Redux store with new data
    return userProfile;
  };

  return { getUserProfile };
};

export default useUserData;
