import React from "react";

const DiscourseComponent = ({
  title,
  speakerTitle,
  speakerName,
  speakerCalling,
  source,
  excerpt,
}) => {
  return (
    <div className="pl-4 border-l-4 border-blue-500">
      <h3 className="font-semibold">{title}</h3>
      <p className="italic text-gray-600">
        {speakerTitle} {speakerName}, {speakerCalling}
      </p>
      <p className="italic text-gray-600">"{excerpt}"</p>
      <p className="font-semibold text-right text-gray-500">- {source}</p>
    </div>
  );
};

export default DiscourseComponent;
