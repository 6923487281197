// actions/userActions.js
import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const updateUserProfilePhoto = createAsyncThunk(
  "user/updateUserProfilePhoto",
  async ({ uid, photoURL }, thunkAPI) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/users/update`,
        {
          uid,
          photoURL,
        }
      );
      return response.data.user;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

export const updateUserProfile = createAsyncThunk(
  "user/updateUserProfile",
  async (profileData, thunkAPI) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/users/update`, profileData);
      return response.data.user;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

export const fetchUserProfileData = createAsyncThunk(
  "user/fetchUserProfileData",
  async (uid, thunkAPI) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/users/${uid}`
      );
      return response.data.user;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

export const fetchUserAssessmentData = createAsyncThunk(
  "user/fetchUserAssessmentData",
  async (uid, thunkAPI) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/answers/${uid}`
      );
      console.log("Response from fetchUserAssessmentData:", response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);
