import React from "react";
import { FaDiscord } from "react-icons/fa";
/* import { FaXTwitter, FaInstagram, FaTiktok } from "react-icons/fa6"; */

const Footer = () => {
  return (
    <footer className="border-t-2 bg-primary-100 border-primary-400 text-primary-800 ">
      <div className="container px-6 py-10 mx-auto">
        {/* Top Section: Logo and Navigation */}
        <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-6">

          {/* Navigation Sections */}
          {/* App Features Section */}
          <div className="col-span-2">
            <h3 className="mb-4 font-bold uppercase text-md">Features</h3>
            <ul className="">
              <li className="mb-2">
                <a href="/self-assessment" className="hover:underline">
                  Self-Assessment
                </a>
              </li>
              <li className="mb-2">
                <a href="/articles" className="hover:underline">
                  <s>What Lack I Yet?</s> (Coming Soon!)
                </a>
              </li>
              <li className="mb-2">
                <a href="/resources" className="hover:underline">
                  <s>My Study Plan</s> (Coming Soon!)
                </a>
              </li>
              <li className="mb-2">
                <a href="/resources" className="hover:underline">
                  <s>Global Discipleship</s> (Coming Soon!)
                </a>
              </li>
            </ul>
          </div>

          {/* Connect Section */}
          <div>
            <h3 className="mb-4 font-bold uppercase text-md">Connect</h3>
            <ul className="">
              <li className="mb-2">
                <a
                  href={`${process.env.REACT_APP_DISCORD_INVITE}`}
                  className="hover:underline"
                >
                  Discord Server
                </a>
              </li>
              <li className="mb-2">
                <a
                  href={`${process.env.REACT_APP_REDDIT}`}
                  className="hover:underline"
                >
                  Subreddit
                </a>
              </li>
              <li className="mb-2">
                <a
                  href={`${process.env.REACT_APP_CANNY}`}
                  className="hover:underline"
                >
                  Roadmap & Feature Requests
                </a>
              </li>
            </ul>
          </div>

          {/* About Us Section */}
          <div>
            <h3 className="mb-4 font-bold uppercase text-md">About</h3>
            <ul className="">
              <li className="mb-2">
                <a href="/about" className="hover:underline">
                  My Story
                </a>
              </li>
              <li className="mb-2">
                <a href="about" className="hover:underline">
                  <s>Donate</s> (Coming Soon!)
                </a>
              </li>
              <li className="mb-2">
                <a href="#" className="hover:underline">
                  <s>Join My Team</s> (Coming Soon!)
                </a>
              </li>
            </ul>
          </div>
        </div>

        {/* Divider */}
        <hr className="w-full m-6 mx-auto border-wise-dark" />

        {/* Bottom Section: Standard Links and Social Media */}
        <div className="flex flex-col items-center justify-between md:flex-row">
          {/* Standard Links */}
          <div className="flex flex-wrap justify-center mb-4 md:mb-0">
            <a
              href="mailto:support@pathtochrist.life"
              className="mr-4 text-sm hover:underline"
            >
              Email
            </a>
            <a href="/privacy-policy" className="mr-4 text-sm hover:underline">
              Privacy Policy
            </a>
            <a href="/terms-of-service" className="text-sm hover:underline">
              Terms of Service
            </a>
          </div>

          {/* Social Media Links */}
          <div className="flex space-x-6">
            <a
              href={`${process.env.REACT_APP_DISCORD_INVITE}`}
              className=" hover"
              aria-label="Discord"
            >
              <FaDiscord />
            </a>
            {/* <a
              href="#"
              className=" hover"
              aria-label="Facebook"
            >
              <FaFacebookF />
            </a> */}
            {/* <a
              href="#"
              className=" hover"
              aria-label="Twitter"
            >
              <FaXTwitter />
            </a> */}
            {/* <a
              href="#"
              className=" hover"
              aria-label="Instagram"
            >
              <FaInstagram />
            </a> */}
            {/* <a
              href="#"
              className=" hover"
              aria-label="TikTok"
            >
              <FaTiktok />
            </a> */}
          </div>
          <p className="text-sm ">
            © {new Date().getFullYear()} Path to Christ. All rights reserved.
          </p>
        </div>

        {/* Footer Bottom */}
        <div className="mt-6 text-center"></div>
      </div>
    </footer>
  );
};

export default Footer;
