import React from "react";
import Navbar from "../components/constants/Navbar";
import Footer from "../components/constants/Footer";

const TermsOfService = () => {
  return (
    <div className="min-h-screen bg-gray-100">
      <Navbar />
      <div className="container max-w-3xl px-4 py-8 mx-auto">
        <h1 className="mb-6 text-4xl font-bold text-center text-gray-800">
          Terms of Service
        </h1>
        <p className="mb-4 text-gray-700">
          <em>Last updated: October 5th, 2024</em>
        </p>

        <h2 className="mt-8 mb-4 text-2xl font-semibold text-gray-800">
          1. Acceptance of Terms
        </h2>
        <p className="mb-4 text-gray-700">
          By accessing or using <strong>Discipleship</strong> ("the App"), you
          agree to be bound by these Terms of Service ("Terms"). If you disagree
          with any part of the terms, you may not access the App.
        </p>

        <h2 className="mt-8 mb-4 text-2xl font-semibold text-gray-800">
          2. Use of the App
        </h2>
        <ul className="mb-4 ml-6 text-gray-700 list-disc">
          <li>
            <strong>Eligibility:</strong> You must be at least 13 years old to
            use the App.
          </li>
          <li>
            <strong>Account Security:</strong> You are responsible for
            safeguarding the password that you use to access the App.
          </li>
          <li>
            <strong>Prohibited Activities:</strong> You agree not to misuse the
            App, including but not limited to distributing harmful content,
            spamming, or violating any laws.
          </li>
        </ul>

        <h2 className="mt-8 mb-4 text-2xl font-semibold text-gray-800">
          3. User Content
        </h2>
        <ul className="mb-4 ml-6 text-gray-700 list-disc">
          <li>
            <strong>Ownership:</strong> You retain ownership of any content you
            submit, post, or display on or through the App.
          </li>
          <li>
            <strong>License:</strong> By submitting content, you grant us a
            non-exclusive, royalty-free license to use, modify, and display such
            content.
          </li>
        </ul>

        <h2 className="mt-8 mb-4 text-2xl font-semibold text-gray-800">
          4. Intellectual Property
        </h2>
        <p className="mb-4 text-gray-700">
          All content, features, and functionality on the App are the exclusive
          property of <strong>Discipleship</strong> and are protected by
          international copyright, trademark, patent, trade secret, and other
          intellectual property laws.
        </p>

        <h2 className="mt-8 mb-4 text-2xl font-semibold text-gray-800">
          5. Termination
        </h2>
        <p className="mb-4 text-gray-700">
          We may terminate or suspend your account immediately, without prior
          notice, for any reason whatsoever, including without limitation if you
          breach the Terms.
        </p>

        <h2 className="mt-8 mb-4 text-2xl font-semibold text-gray-800">
          6. Limitation of Liability
        </h2>
        <p className="mb-4 text-gray-700">
          In no event shall <strong>Discipleship</strong>, nor its directors,
          employees, partners, agents, suppliers, or affiliates, be liable for
          any indirect, incidental, special, consequential, or punitive damages.
        </p>

        <h2 className="mt-8 mb-4 text-2xl font-semibold text-gray-800">
          7. Disclaimer
        </h2>
        <p className="mb-4 text-gray-700">
          Your use of the App is at your sole risk. The App is provided on an
          "AS IS" and "AS AVAILABLE" basis.
        </p>

        <h2 className="mt-8 mb-4 text-2xl font-semibold text-gray-800">
          8. Changes to Terms
        </h2>
        <p className="mb-4 text-gray-700">
          We reserve the right, at our sole discretion, to modify or replace
          these Terms at any time. We will provide notice of changes by updating
          the "Last updated" date.
        </p>

        <h2 className="mt-8 mb-4 text-2xl font-semibold text-gray-800">
          9. Contact Us
        </h2>
        <p className="mb-4 text-gray-700">
          If you have any questions about these Terms, please contact us at{" "}
          <a
            href="mailto:support@pathtochrist.life"
            className="text-blue-600 hover:underline"
          >
            support@pathtochrist.life
          </a>
          .
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default TermsOfService;
