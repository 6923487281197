import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { logPageView } from "../utils/analytics"; // Your analytics logic

const PageTracker = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    logPageView();
  }, [location]);

  return children;
};

export default PageTracker;
