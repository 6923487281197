// src/components/navigation/MobileMenu.js

import { useState } from "react";
import { Link } from "react-router-dom";
import { Dialog, DialogPanel } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";

export default function MobileMenu({
  primaryMenuItems,
  secondaryMenuItems,
  profileItems,
  user,
  handleLogOut,
  openModal,
}) {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <header>
      {/* Menu Button */}
      <nav
        aria-label="Global"
        className="flex items-center justify-between p-6 mx-auto max-w-7xl lg:px-8"
      >
        <div className="flex lg:hidden">
          <button
            type="button"
            onClick={() => setMobileMenuOpen(true)}
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon aria-hidden="true" className="w-6 h-6" />
          </button>
        </div>
      </nav>

      {/* Mobile Menu */}
      <Dialog
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
        className="lg:hidden"
      >
        <div className="fixed inset-0 z-10" />

        <DialogPanel className="fixed inset-y-0 right-0 z-10 flex flex-col justify-between w-full overflow-y-auto bg-white sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="p-6">
            {/* Logo */}
            <div className="flex items-center justify-between">
              <Link to="/" className="-m-1.5 p-1.5">
                <span className="sr-only">Discipleship</span>
                <img
                  alt="Discipleship Logo"
                  src="https://firebasestorage.googleapis.com/v0/b/christlike-attributes-c0fd3.appspot.com/o/discipleship-official%2Fdisciplesheep-nobg.png?alt=media&token=dedeaedd-b5b9-495e-9802-7dffd423c9d1"
                  className="w-auto h-8"
                />
              </Link>
              <button
                type="button"
                onClick={() => setMobileMenuOpen(false)}
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon aria-hidden="true" className="w-6 h-6" />
              </button>
            </div>

            {/* Menu Items */}
            <div className="flow-root mt-6">
              {user ? (
                <div className="-my-6 divide-y divide-gray-500/10">
                  <div className="py-6 space-y-2">
                    {primaryMenuItems.map((item) => (
                      <Link
                        key={item.name}
                        to={item.href}
                        onClick={() => setMobileMenuOpen(false)}
                        className="flex items-center p-3 -mx-3 text-base font-semibold leading-7 text-gray-900 rounded-lg group gap-x-6 hover:bg-gray-50"
                      >
                        <div className="flex items-center justify-center flex-none rounded-lg h-11 w-11 bg-gray-50 group-hover:bg-white">
                          <item.icon
                            aria-hidden="true"
                            className="w-6 h-6 text-gray-600 group-hover:text-indigo-600"
                          />
                        </div>
                        {item.name}
                      </Link>
                    ))}
                  </div>
                  <div className="py-6 space-y-2">
                    {secondaryMenuItems.map((item) => (
                      <Link
                        key={item.name}
                        to={item.href}
                        onClick={() => setMobileMenuOpen(false)}
                        className="block px-3 py-2 -mx-3 text-base font-semibold leading-7 text-gray-900 rounded-lg hover:bg-gray-50"
                      >
                        {item.name}
                      </Link>
                    ))}
                  </div>
                  <div className="-my-6 divide-y divide-gray-500/10">
                    <div className="py-6 space-y-2 text-gray-900">
                      Signed in as{" "}
                      <p className="font-semibold text-gray-900">
                        {user.displayName || user.email}
                      </p>
                      {/* Log out */}
                      <button
                        onClick={() => {
                          handleLogOut();
                          setMobileMenuOpen(false);
                        }}
                        className="block w-full px-3 py-2 -mx-3 text-base font-semibold leading-7 text-left text-gray-900 rounded-lg hover:bg-gray-50"
                      >
                        Sign out
                      </button>
                    </div>
                    <div className="py-6 space-y-2">
                      {profileItems.map((item) => (
                        <Link
                          key={item.name}
                          to={item.href}
                          onClick={() => setMobileMenuOpen(false)}
                          className="block px-3 py-2 -mx-3 text-base font-semibold leading-7 text-gray-900 rounded-lg hover:bg-gray-50"
                        >
                          {item.name}
                        </Link>
                      ))}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="-my-6 divide-y divide-gray-500/10">
                  <div className="py-6 space-y-2">
                    {secondaryMenuItems.map((item) => (
                      <Link
                        key={item.name}
                        to={item.href}
                        onClick={() => setMobileMenuOpen(false)}
                        className="block px-3 py-2 -mx-3 text-base font-semibold leading-7 text-gray-900 rounded-lg hover:bg-gray-50"
                      >
                        {item.name}
                      </Link>
                    ))}
                  </div>
                  <div className="py-6 space-y-2">
                    <button
                      onClick={() => {
                        setMobileMenuOpen(false);
                        openModal(); // Open the login modal
                      }}
                      className="block w-full p-3 text-base font-semibold leading-7 text-left text-gray-900 rounded-lg hover:bg-gray-100"
                    >
                      Log In
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </DialogPanel>
      </Dialog>
    </header>
  );
}
