import ReactGA from "react-ga4";

export const initGA = (trackingID) => {
  ReactGA.initialize(trackingID);
};

export const logPageView = () => {
  const pagePath = window.location.pathname + window.location.search;
  ReactGA.send({ hitType: "pageview", page: pagePath });
};
