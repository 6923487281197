// src/components/charts/OverallStatsGraph.js

import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Cell,
  Legend,
} from "recharts";

const OverallStatsGraph = ({ sectionStats, historicalSectionStats }) => {
  const colors = {
    Faithful: "#95CBE4",
    Pure: "#DCE5EF",
    Loving: "#EF837B",
    Hopeful: "#C8EA6C",
    Patient: "#38b2ac",
    Meek: "#C1B1A8",
    True: "#EBD224",
    Wise: "#FFE55D",
    Grateful: "#F3A616",
    Joyous: "#FFE55D",
    Merciful: "#2F9393",
    Peaceful: "#95CBE4",
  };

  const mergedData = sectionStats.map((latestStat) => {
    const historicalStat = historicalSectionStats.find(
      (stat) => stat.section === latestStat.section
    );
    return {
      section: latestStat.section,
      latestAverage: latestStat.average,
      historicalAverage: historicalStat ? historicalStat.average : null,
    };
  });

  return (
    <div className="container p-4 mx-auto bg-white rounded-lg shadow-lg">
      <ResponsiveContainer width="100%" height={300}>
        <BarChart
          data={mergedData}
          margin={{ top: 20, right: 20, left: -10, bottom: 40 }}
          barCategoryGap="20%"
        >
          <XAxis
            dataKey="section"
            tick={{ fontSize: 12 }}
            interval={0}
            angle={-45}
            textAnchor="end"
            height={80}
          />
          <YAxis
            domain={[0, 5]}
            tick={{ fontSize: 12 }}
            ticks={[0, 1, 2, 3, 4, 5]}
          />
          <Tooltip formatter={(value) => value.toFixed(1)} />
          <Legend />
          <Bar
            dataKey="latestAverage"
            name="Latest Score"
            fill="#8884d8"
            radius={[10, 10, 0, 0]}
            maxBarSize={50}
          >
            {mergedData.map((entry, index) => (
              <Cell
                key={`cell-latest-${index}`}
                fill={colors[entry.section] || "#8884d8"}
              />
            ))}
          </Bar>
          {historicalSectionStats.length > 0 && (
            <Bar
              dataKey="historicalAverage"
              name="Historical Score"
              fill="#82ca9d"
              radius={[10, 10, 0, 0]}
              maxBarSize={50}
            >
              {mergedData.map((entry, index) => (
                <Cell key={`cell-historical-${index}`} fill="#82ca9d" />
              ))}
            </Bar>
          )}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default OverallStatsGraph;
