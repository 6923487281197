import React from "react";

const TempleIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
      className=""
    >
      <path
        d="M7.57,45.6v-15.84c0-1.03.25-1.68.63-1.68h3.21c.38,0,.62.66.62,1.68v15.84h-4.46M9.81,7.54c-.18,0-.37.2-.54.58L2.19,24.19v19.7c0,3.11.69,4.94,1.85,4.94h11.54c1.08,0,1.75-1.57,1.84-4.27-.03-.46-.04-.94-.04-1.46v-19.02l-7.04-15.97c-.17-.39-.35-.58-.53-.58"
        fill="#000"
      />
      <path
        d="M22.76,45.08v-18.35c0-1.19.25-1.95.63-1.95h3.21c.38,0,.62.76.62,1.95v18.35h-4.46M25,1c-.18,0-.37.23-.54.67l-7.08,18.62v3.8l.05.12v19.68c0,.23,0,.46-.01.68.17,2.71.82,4.27,1.81,4.27h11.54c1,0,1.65-1.55,1.81-4.27,0-.22-.01-.45-.01-.68v-19.7l.05-.12v-3.76L25.53,1.67c-.17-.45-.35-.67-.53-.67"
        fill="#000"
      />
      <path
        d="M17.38,24.09v19.02c0,.52.01,1.01.04,1.46,0-.22.01-.45.01-.68v-19.68l-.05-.12"
        fill="#000"
      />
      <path
        d="M37.95,45.6v-15.84c0-1.03.25-1.68.63-1.68h3.21c.38,0,.62.66.62,1.68v15.84h-4.46M40.19,7.54c-.18,0-.37.2-.54.58l-7.03,15.95v19.04c0,.52-.01,1.01-.04,1.46.09,2.7.77,4.27,1.84,4.27h11.54c1.16,0,1.85-1.83,1.85-4.94v-19.68l-7.09-16.09c-.17-.39-.35-.58-.53-.58"
        fill="#000"
      />
      <path
        d="M32.62,24.07l-.05.12v19.7c0,.23,0,.46.01.68.03-.46.04-.94.04-1.46v-19.04"
        fill="#000"
      />
      <path
        d="M2.24,44.44h45.57c0,2.52-2.04,4.56-4.56,4.56H6.8c-2.52,0-4.56-2.04-4.56-4.56h0Z"
        fill="#000"
      />
    </svg>
  );
};

export default TempleIcon;