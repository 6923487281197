import React from "react";
import Navbar from "../components/constants/Navbar";
import Footer from "../components/constants/Footer";

const PrivacyPolicy = () => {
  return (
    <div className="min-h-screen bg-gray-100">
      <Navbar />
      <div className="container max-w-3xl px-4 py-8 mx-auto">
        <h1 className="mb-6 text-4xl font-bold text-center text-gray-800">
          Privacy Policy
        </h1>
        <p className="mb-4 text-gray-700">
          <em>Last updated: October 5th, 2024</em>
        </p>

        <h2 className="mt-8 mb-4 text-2xl font-semibold text-gray-800">
          1. Introduction
        </h2>
        <p className="mb-4 text-gray-700">
          Welcome to <strong>Discipleship</strong> ("we," "our," or "us").
          Discipleship is an app created and maintained by Michael DiPaolo,
          operated under the non-profit organization "Path to Christ." We are
          committed to protecting your personal information and your right to
          privacy. This Privacy Policy explains how we collect, use, disclose,
          and safeguard your information when you use our application.
        </p>

        <h2 className="mt-8 mb-4 text-2xl font-semibold text-gray-800">
          2. Definitions
        </h2>
        <p className="mb-4 text-gray-700">
          In this Privacy Policy, "Discipleship" refers to our
          application running on the web, iOS, and Android operating systems and any
          related services. "You" and "your" refer to users of our app.
        </p>

        <h2 className="mt-8 mb-4 text-2xl font-semibold text-gray-800">
          3. Information We Collect
        </h2>
        <p className="mb-4 text-gray-700">
          We collect various types of information, including:
        </p>
        <ul className="mb-4 ml-6 text-gray-700 list-disc">
          <li>
            <strong>Personal Information:</strong> Email address, display name,
            profile photo, and optional information such as location,
            preferences, religious denomination, goals, and other personal
            insights you choose to provide.
          </li>
          <li>
            <strong>Usage Data:</strong> Information about your interactions
            with the app, such as quiz responses, assessment results, and
            progress tracking.
          </li>
          <li>
            <strong>Device Information:</strong> Information about the device
            you use to access the app, including IP address, browser type,
            operating system, and mobile network information.
          </li>
        </ul>

        <h2 className="mt-8 mb-4 text-2xl font-semibold text-gray-800">
          4. How We Use Your Information
        </h2>
        <p className="mb-4 text-gray-700">We use your information to:</p>
        <ul className="mb-4 ml-6 text-gray-700 list-disc">
          <li>
            Provide and maintain our app's functionalities and personalized
            features.
          </li>
          <li>
            Facilitate your participation in assessments, quizzes, and other
            interactive features.
          </li>
          <li>
            Send administrative information like updates, security alerts, and
            support messages.
          </li>
          <li>
            Respond to your comments, questions, and provide user support.
          </li>
          <li>
            Improve our app based on usage patterns, feedback, and user
            preferences.
          </li>
          <li>
            Enable community features, such as connecting with other users (only
            with your consent).
          </li>
        </ul>

        <h2 className="mt-8 mb-4 text-2xl font-semibold text-gray-800">
          5. Disclosure of Your Information
        </h2>
        <p className="mb-4 text-gray-700">We may share your information:</p>
        <ul className="mb-4 ml-6 text-gray-700 list-disc">
          <li>
            <strong>With Service Providers:</strong> Third-party vendors who
            perform services on our behalf, such as Firebase for authentication
            and data storage, and analytics providers for app usage data.
          </li>
          <li>
            <strong>Legal Obligations:</strong> If required by law, regulation,
            or legal process, such as a court order or subpoena.
          </li>
          <li>
            <strong>Business Transfers:</strong> In connection with, or during
            negotiations of, any merger, sale of assets, financing, or
            acquisition of all or a portion of our organization.
          </li>
        </ul>

        <h2 className="mt-8 mb-4 text-2xl font-semibold text-gray-800">
          6. Your Choices and Control Over Your Information
        </h2>
        <p className="mb-4 text-gray-700">You have the right to:</p>
        <ul className="mb-4 ml-6 text-gray-700 list-disc">
          <li>
            Access, update, or delete your account information at any time
            through the app's settings.
          </li>
          <li>
            Opt-out of certain data collection practices by adjusting your
            device settings or contacting us directly.
          </li>
          <li>Request a copy of the personal data we hold about you.</li>
        </ul>

        <h2 className="mt-8 mb-4 text-2xl font-semibold text-gray-800">
          7. Security of Your Information
        </h2>
        <p className="mb-4 text-gray-700">
          We implement industry-standard security measures to protect your
          personal information. However, no method of transmission over the
          internet or electronic storage is completely secure. We cannot
          guarantee absolute security.
        </p>

        <h2 className="mt-8 mb-4 text-2xl font-semibold text-gray-800">
          8. Children's Privacy
        </h2>
        <p className="mb-4 text-gray-700">
          Our app is not intended for use by individuals under the age of 13. We
          do not knowingly collect personal information from children under 13.
          If we become aware that we have collected such information, we will
          take steps to delete it promptly.
        </p>

        <h2 className="mt-8 mb-4 text-2xl font-semibold text-gray-800">
          9. Third-Party Links and Services
        </h2>
        <p className="mb-4 text-gray-700">
          Our app may contain links to third-party websites or services. We are
          not responsible for the privacy practices of these external sites. We
          encourage you to review their privacy policies before providing them
          with your personal information.
        </p>

        <h2 className="mt-8 mb-4 text-2xl font-semibold text-gray-800">
          10. Changes to This Privacy Policy
        </h2>
        <p className="mb-4 text-gray-700">
          We may update our Privacy Policy from time to time to reflect changes
          in our practices or for other operational, legal, or regulatory
          reasons. We will notify you of any significant changes by updating the
          "Last updated" date and, if required, through additional
          notifications.
        </p>

        <h2 className="mt-8 mb-4 text-2xl font-semibold text-gray-800">
          11. Contact Us
        </h2>
        <p className="mb-4 text-gray-700">
          If you have any questions or concerns about this Privacy Policy or our
          data practices, please contact us at{" "}
          <a
            href="mailto:support@pathtochrist.life"
            className="text-blue-600 hover:underline"
          >
            support@pathtochrist.life
          </a>
          .
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
