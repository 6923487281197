import React from "react";
import Navbar from "../components/constants/Navbar";
import Footer from "../components/constants/Footer";
import {
  FaPrayingHands,
  FaTint,
  FaHeart,
  FaSun,
  FaHourglassHalf,
  FaFeatherAlt,
  FaCheck,
  FaBook,
  FaHands,
  FaLaugh,
  FaHandsHelping,
  FaDove,
} from "react-icons/fa";

// Attribute data with scripture, characters, and organisms
const attributes = [
  {
    name: "Faithful",
    scripture:
      "Be thou faithful unto death, and I will give thee a crown of life. – Revelation 2:10",
    character: "Nephi",
    organism: "Eagle (Soars with trust in unseen winds)",
    color: "faithful",
    faIcon: "FaPrayingHands",
  },
  {
    name: "Pure",
    scripture:
      "Blessed are the pure in heart: for they shall see God. – Matthew 5:8",
    character: "Mary, the Mother of Jesus",
    organism: "Swan (Symbol of grace and purity)",
    color: "pure",
    faIcon: "FaTint",
  },
  {
    name: "Loving",
    scripture:
      "A new commandment I give unto you, That ye love one another. – John 13:34",
    character: "John the Beloved",
    organism: "Dog (Represents loyalty and unconditional love)",
    color: "loving",
    faIcon: "FaHeart",
  },
  {
    name: "Hopeful",
    scripture: "Be of good cheer; I have overcome the world. – John 16:33",
    character: "Mormon",
    organism: "Sunflower (Represents hope and optimism)",
    color: "hopeful",
    faIcon: "FaSun",
  },
  {
    name: "Patient",
    scripture: "In your patience possess ye your souls. – Luke 21:19",
    character: "Job",
    organism: "Tortoise (Represents patience and endurance)",
    color: "patient",
    faIcon: "FaHourglassHalf",
  },
  {
    name: "Meek/Submissive",
    scripture:
      "Blessed are the meek: for they shall inherit the earth. – Matthew 5:5 || Not my will, but thine, be done. – Luke 22:42",
    character: "Moses",
    organism:
      "Lamb (Represents gentleness and submission) || Sheep (Represents submission and obedience)",
    color: "meek",
    faIcon: "FaFeatherAlt",
  },
  {
    name: "True",
    scripture:
      "I am the way, the truth, and the life: no man cometh unto the Father, but by me. – John 14:6",
    character: "Abinadi",
    organism: "Owl (Represents wisdom and truth)",
    color: "true",
    faIcon: "FaCheck",
  },
  {
    name: "Wise",
    scripture:
      "Be ye therefore wise as serpents, and harmless as doves. – Matthew 10:16",
    character: "Solomon",
    organism: "Serpent (Represents wisdom and cunning)",
    color: "wise",
    faIcon: "FaBook",
  },
  {
    name: "Grateful",
    scripture: "Father, I thank thee that thou hast heard me. – John 11:41",
    character: "Ammon",
    organism: "Hummingbird (Symbol of gratitude for small blessings)",
    color: "grateful",
    faIcon: "FaHandsHelping",
  },
  {
    name: "Joyous",
    scripture:
      "These things have I spoken unto you, that my joy might remain in you, and that your joy might be full. – John 15:11",
    character: "Enos",
    organism: "Dolphin (Known for its joyful nature)",
    color: "joyous",
    faIcon: "FaLaugh",
  },
  {
    name: "Merciful",
    scripture:
      "Be ye therefore merciful, as your Father also is merciful. – Luke 6:36",
    character: "The Good Samaritan",
    organism: "Elephant (Represents empathy and mercy)",
    color: "merciful",
    faIcon: "FaHands",
  },
  {
    name: "Peaceful",
    scripture: "Peace I leave with you, my peace I give unto you. – John 14:27",
    character: "Melchizedek",
    organism: "Dove (Represents peace and the Holy Ghost)",
    color: "peaceful",
    faIcon: "FaDove",
  },
];

// Map faIcon to actual icon components
const iconMap = {
  FaPrayingHands: FaPrayingHands,
  FaTint: FaTint,
  FaHeart: FaHeart,
  FaSun: FaSun,
  FaHourglassHalf: FaHourglassHalf,
  FaFeatherAlt: FaFeatherAlt,
  FaCheck: FaCheck,
  FaBook: FaBook,
  FaHands: FaHands,
  FaLaugh: FaLaugh,
  FaHandsHelping: FaHandsHelping,
  FaDove: FaDove,
};

// Color mapping for each attribute
const colorMap = {
  faithful: {
    light: "bg-[#95CBE4]", // Cornflower - old palette for faith
    dark: "dark:bg-[#235789]", // New dark shade
  },
  pure: {
    light: "bg-[#DCE5EF]", // Water - purity from old palette
    dark: "dark:bg-[#E0E0E0]", // New dark shade
  },
  loving: {
    light: "bg-[#EF837B]", // Geraldine - love from old palette
    dark: "dark:bg-[#C0392B]", // New dark shade
  },
  hopeful: {
    light: "bg-[#C8EA6C]", // Conifer - hope from old palette
    dark: "dark:bg-[#F1C40F]", // New dark shade
  },
  patient: {
    light: "bg-[#BA82AD]", // Bouquet - patience from old palette
    dark: "dark:bg-[#16A085]", // New dark shade
  },
  meek: {
    light: "bg-[#C1B1A8]", // Silk - humility from old palette
    dark: "dark:bg-[#7B7D7D]", // New dark shade
  },
  true: {
    light: "bg-[#EBD224]", // Sunshine - truth from old palette
    dark: "dark:bg-[#D35400]", // New dark shade
  },
  wise: {
    light: "bg-[#FFE55D]", // Sunshine - wisdom from old palette
    dark: "dark:bg-[#F39C12]", // New dark shade
  },
  grateful: {
    light: "bg-[#F3A616]", // Warmth - gratitude from old palette
    dark: "dark:bg-[#F39C12]", // New dark shade
  },
  joyous: {
    light: "bg-[#FFE55D]", // Sunshine - joy from old palette
    dark: "dark:bg-[#F4D03F]", // New dark shade
  },
  merciful: {
    light: "bg-[#2F9393]", // Lochinvar - forgiveness/mercy from old palette
    dark: "dark:bg-[#2980B9]", // New dark shade
  },
  peaceful: {
    light: "bg-[#95CBE4]", // Cornflower - peace from old palette
    dark: "dark:bg-[#3498DB]", // New dark shade
  },
};

const MarksOfDiscipleship = () => {
  return (
    <div className="flex flex-col min-h-screen bg-haze">
      <Navbar />
      <div className="container px-4 py-10 mx-auto">
        <h1 className="mb-12 text-5xl font-extrabold text-center text-gray-900 dark:text-gray-200">
          Christlike Attributes Glossary
        </h1>

        <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
          {attributes.map((attribute, index) => {
            const colors = colorMap[attribute.color];
            const IconComponent = iconMap[attribute.faIcon];
            return (
              <div
                key={index}
                className={`${colors.light} p-8 rounded-lg shadow-xl hover:shadow-2xl transition-shadow duration-300`}
              >
                <div className="flex items-center mb-4">
                  <IconComponent className="mr-4 text-4xl text-gray-800 dark:text-gray-100" />
                  <h2 className="text-3xl font-semibold text-gray-800 dark:text-gray-100">
                    Be Ye {attribute.name}
                  </h2>
                </div>
                <p className="mb-4 text-xl text-gray-700 dark:text-gray-300">
                  "
                  <strong className="font-semibold">
                    {attribute.scripture}
                  </strong>
                  "
                </p>
                <p className="flex items-center mb-3 text-lg text-gray-800 dark:text-gray-100">
                  <FaCheck className="mr-2 text-2xl text-green-600 dark:text-green-400" />
                  <strong>Character:</strong> {attribute.character}
                </p>
                <p className="flex items-center text-lg text-gray-800 dark:text-gray-100">
                  <FaCheck className="mr-2 text-2xl text-green-600 dark:text-green-400" />
                  <strong>Symbolic Organism:</strong> {attribute.organism}
                </p>
              </div>
            );
          })}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default MarksOfDiscipleship;
