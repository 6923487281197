// src/components/constants/ProgressBar.js

import React from "react";

const ProgressBar = ({ progress }) => {
  // Determine if progress is over 50% to adjust text color
  const textColor = progress > 49 ? "text-white" : "text-gray-800";

  return (
    <>
      <h4 className="sr-only">Progress</h4>
      <div aria-hidden="true" className="mt-6">
        <div className="flex items-center justify-center w-full mb-6 bg-transparent h-fit">
          <div className="relative w-3/4 h-8 max-w-xl overflow-hidden bg-gray-200 border-2 border-gray-300 rounded-full shadow-md">
            <div
              className="absolute top-0 left-0 h-full bg-secondary-600"
              style={{ width: `${progress}%` }}
            ></div>
            <div
              className={`absolute inset-0 flex items-center justify-center text-lg font-bold ${textColor}`}
              style={{ textShadow: "1px 1px 2px rgba(0,0,0,0.2)" }}
            >
              {progress}%
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProgressBar;
