import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import StudyGuideComponent from "../components/study-guides/StudyGuideComponent";
import axios from "axios";

const StudyGuide = () => {
  const { questionId } = useParams(); // Get the question ID from the route
  const [studyGuideData, setStudyGuideData] = useState(null);
  const [questionData, setQuestionData] = useState(null); // Add state for question data
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch the study guide data along with the question data for the given question ID
    const fetchStudyGuideData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/study-guides/${questionId}`
        );

        console.log("API Response:", response.data); // Debugging: Check API response

        setStudyGuideData(response.data.studyGuide);
        setQuestionData(response.data.question); // Store question data
      } catch (err) {
        setError("Failed to load study guide data.");
      } finally {
        setLoading(false);
      }
    };

    fetchStudyGuideData();
  }, [questionId]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="container py-8 mx-auto">
      {studyGuideData && questionData ? (
        <StudyGuideComponent
          title={studyGuideData.title}
          scriptures={studyGuideData.scriptures}
          discourses={studyGuideData.discourses}
          exercises={studyGuideData.exercises}
          question={questionData} // Pass the question data to the component
        />
      ) : (
        <p>No study guide found for this question.</p>
      )}
    </div>
  );
};

export default StudyGuide;
