import { useState } from "react";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/24/outline";
import {
  getAuth,
  setPersistence,
  signInWithEmailAndPassword as firebaseSignIn,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  browserLocalPersistence,
  browserSessionPersistence,
} from "firebase/auth";
import { useDispatch } from "react-redux";
import axios from "axios";
import { login } from "../../store/reducers/userSlice";

const AuthModal = ({ onClose }) => {
  const [open] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [resetPassword, setResetPassword] = useState(false);
  const [persistSession, setPersistSession] = useState(false);

  const auth = getAuth();
  const dispatch = useDispatch();

  const validatePassword = (password) => {
    const passwordRegex = /((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W]).{6,20})/;
    return passwordRegex.test(password);
  };

  const handleSignIn = async (e) => {
    e.preventDefault();
    if (!validatePassword(password)) {
      setErrorMessage("Password must meet complexity requirements.");
      return;
    }

    try {
      const persistenceType = persistSession
        ? browserSessionPersistence
        : browserLocalPersistence;
      await setPersistence(auth, persistenceType);
      const userCredential = await firebaseSignIn(auth, email, password);
      dispatch(
        login({
          uid: userCredential.user.uid,
          email: userCredential.user.email,
        })
      );
      onClose();
    } catch (error) {
      if (error.code === "auth/user-not-found") handleSignUp();
      else setErrorMessage("An error occurred. Please try again.");
    }
  };

  const handleSignUp = async () => {
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      await axios.post(`${process.env.REACT_APP_API_URL}/api/users/`, {
        uid: userCredential.user.uid,
        email,
      });
      dispatch(
        login({
          uid: userCredential.user.uid,
          email: userCredential.user.email,
        })
      );
      onClose();
    } catch {
      setErrorMessage("Error during sign-up. Please try again.");
    }
  };

  const handlePasswordReset = async () => {
    if (!email) {
      setErrorMessage("Enter your email for password reset.");
      return;
    }
    try {
      await sendPasswordResetEmail(auth, email);
      setErrorMessage("Password reset email sent.");
      setResetPassword(false);
    } catch {
      setErrorMessage("Error sending reset email.");
    }
  };

  return (
    <Dialog open={open} onClose={onClose} className="relative z-10">
      <DialogBackdrop className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
      <div className="fixed inset-0 z-10 flex items-center justify-center p-4 overflow-y-auto text-center">
        <DialogPanel className="w-full max-w-md p-6 overflow-hidden text-left text-gray-900 transition-all transform bg-white rounded-lg shadow-xl">
          <div className="flex items-center justify-center mb-6">
            <CheckIcon
              className="w-12 h-12 text-green-600"
              aria-hidden="true"
            />
          </div>
          <DialogTitle
            as="h3"
            className="text-lg font-semibold leading-6 text-center text-gray-900"
          >
            {resetPassword ? "Reset Password" : "Sign in to your account"}
          </DialogTitle>

          {resetPassword ? (
            <form onSubmit={handlePasswordReset} className="mt-4 space-y-6">
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-900"
                >
                  Email address
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>
              {errorMessage && (
                <p className="text-sm text-red-500">{errorMessage}</p>
              )}
              <button
                type="submit"
                className="w-full px-4 py-2 text-sm font-semibold text-white bg-indigo-600 rounded-md hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
              >
                Send Reset Email
              </button>
              <button
                type="button"
                onClick={() => setResetPassword(false)}
                className="w-full px-4 py-2 mt-2 text-sm font-semibold text-gray-700 bg-gray-300 rounded-md hover:bg-gray-400 focus:outline-none"
              >
                Back to Sign In
              </button>
            </form>
          ) : (
            <form onSubmit={handleSignIn} className="mt-4 space-y-6">
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-900"
                >
                  Email address
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>
              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-900"
                >
                  Password
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
                <button
                  type="button"
                  onClick={() => setResetPassword(true)}
                  className="mt-2 text-sm text-indigo-600 hover:underline"
                >
                  Forgot Password?
                </button>
              </div>
              {errorMessage && (
                <p className="text-sm text-red-500">{errorMessage}</p>
              )}
              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="persistSession"
                  checked={persistSession}
                  onChange={() => setPersistSession(!persistSession)}
                  className="w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                />
                <label
                  htmlFor="persistSession"
                  className="block ml-2 text-sm text-gray-900"
                >
                  Remember me
                </label>
              </div>
              <button
                type="submit"
                className="w-full px-4 py-2 text-sm font-semibold text-white bg-indigo-600 rounded-md hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
              >
                Sign In / Sign Up
              </button>
            </form>
          )}
          <div className="mt-4 text-xs text-center text-gray-500">
            By continuing, you agree to our{" "}
            <a
              href="/tos"
              className="font-medium text-indigo-600 hover:underline"
            >
              Terms
            </a>{" "}
            and{" "}
            <a
              href="/privacy-policy"
              className="font-medium text-indigo-600 hover:underline"
            >
              Privacy Policy
            </a>
            .
          </div>
        </DialogPanel>
      </div>
    </Dialog>
  );
};

export default AuthModal;
