// src/pages/AuthPage.js
import React, { useEffect, useState } from "react";
import {
  browserLocalPersistence,
  browserSessionPersistence,
  createUserWithEmailAndPassword,
  getAuth,
  onAuthStateChanged,
  setPersistence,
  signInWithEmailAndPassword as firebaseSignIn,
  getRedirectResult,
  sendEmailVerification,
  sendPasswordResetEmail,
} from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { login } from "../store/reducers/userSlice";
import axios from "axios";

import { MdEmail } from "react-icons/md";
import { FaLock } from "react-icons/fa";

const AuthPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [persistSession, setPersistSession] = useState(false);
  const [resetPassword, setResetPassword] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const auth = getAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    // Handle getRedirectResult
    getRedirectResult(auth)
      .then((result) => {
        if (result) {
          const user = result.user;
          console.log("User signed in with redirect:", user);

          // Dispatch the user to Redux
          dispatch(
            login({
              uid: user.uid,
              email: user.email,
              displayName: user.displayName,
              photoURL: user.photoURL,
            })
          );

          navigate("/"); // Redirect to home page
        } else {
          console.log("No redirect result");
        }
      })
      .catch((error) => {
        console.error("Error during getRedirectResult:", error);
      });

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        console.log("User is signed in:", user);

        // Dispatch the user to Redux
        dispatch(
          login({
            uid: user.uid,
            email: user.email,
            displayName: user.displayName,
            photoURL: user.photoURL,
          })
        );

        navigate("/"); // Redirect if user is signed in
      } else {
        console.log("User is signed out");
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [auth, navigate, dispatch]);

  const validatePassword = (password) => {
    const passwordRegex = /((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W]).{6,20})/;
    return passwordRegex.test(password);
  };

  const createUserInMongoDB = async (uid, email) => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/users/`, {
        uid,
        email,
      });
      console.log("User successfully created in MongoDB");
    } catch {
      setErrorMessage("Failed to create user in MongoDB. Please try again.");
    }
  };

  // Handle Sign Up / Sign In with Email and Password
  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage(null);

    if (!validatePassword(password)) {
      setErrorMessage(
        "Password must be between 6-20 characters, contain at least one uppercase letter, one lowercase letter, one number, and one special character."
      );
      return;
    }

    const persistenceType = persistSession
      ? browserSessionPersistence
      : browserLocalPersistence;

    try {
      // set persistence based on user choice
      await setPersistence(auth, persistenceType);

      // Try to sign in
      const userCredential = await firebaseSignIn(auth, email, password);
      const user = userCredential.user;
      console.log("User successfully signed in");

      // Dispatch the user to Redux
      dispatch(
        login({
          uid: user.uid,
          email: user.email,
          displayName: user.displayName,
          photoURL: user.photoURL,
        })
      );

      navigate("/"); // Redirect after successful sign-in
    } catch (error) {
      console.error("Error during sign-in:", error);

      if (error.code === "auth/user-not-found") {
        try {
          // User not found, create new user
          const userCredential = await createUserWithEmailAndPassword(
            auth,
            email,
            password
          );
          const user = userCredential.user;
          await createUserInMongoDB(user.uid, user.email);
          console.log("User successfully created in both Firebase and MongoDB");

          // Send email verification
          await sendEmailVerification(user);
          console.log("Email verification sent");

          // Dispatch the user to Redux
          dispatch(
            login({
              uid: user.uid,
              email: user.email,
              displayName: user.displayName,
              photoURL: user.photoURL,
            })
          );

          navigate("/"); // Redirect after successful sign-up
        } catch (signupError) {
          console.error("Error during sign-up:", signupError);
          setErrorMessage("Failed to create user. Please try again.");
        }
      } else if (error.code === "auth/wrong-password") {
        setErrorMessage("Incorrect password. Please try again.");
      } else if (error.code === "auth/invalid-credential") {
        setErrorMessage("Invalid credentials. Please try again.");
      } else {
        setErrorMessage("An unexpected error occurred. Please try again.");
      }
    }
  };

  // Handle Password Reset
  const handlePasswordReset = async (e) => {
    e.preventDefault();
    setErrorMessage(null);

    if (!email) {
      setErrorMessage("Please enter your email address.");
      return;
    }

    try {
      await sendPasswordResetEmail(auth, email);
      setErrorMessage("Password reset email sent. Please check your inbox.");
      setResetPassword(false);
    } catch (error) {
      console.error("Error sending password reset email:", error);
      if (error.code === "auth/user-not-found") {
        setErrorMessage("User not found. Please check the email address.");
      } else {
        setErrorMessage("An error occurred. Please try again.");
      }
    }
  };

  return (
    <div className="flex flex-col justify-center flex-1 min-h-full py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="text-4xl font-bold tracking-tight text-center text-gray-900">
          Discipleship
        </h2>
        <img
          src={
            isHovered
              ? "https://firebasestorage.googleapis.com/v0/b/christlike-attributes-c0fd3.appspot.com/o/discipleship-official%2Fdisciplesheep-happy-label.png?alt=media&token=cd8414d1-c2bc-4e2e-a2e8-ea8916a7e33c"
              : "https://firebasestorage.googleapis.com/v0/b/christlike-attributes-c0fd3.appspot.com/o/discipleship-official%2Fdisciplesheep-nobg.png?alt=media&token=dedeaedd-b5b9-495e-9802-7dffd423c9d1"
          }
          className="w-auto h-20 mx-auto mt-6"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          alt="Discipleship Logo"
        />
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
        <div className="px-6 py-12 shadow sm:rounded-lg sm:px-12">
          {resetPassword ? (
            // Password Reset Form
            <form onSubmit={handlePasswordReset} className="space-y-6">
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-900"
                >
                  Email address
                </label>
                <div className="relative mt-2">
                  <input
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    className="bg-white block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm"
                  />
                  <MdEmail className="absolute w-5 h-5 text-gray-900 transform -translate-y-1/2 left-3 top-1/2" />
                </div>
              </div>

              {errorMessage && (
                <p className="text-sm text-red-500">{errorMessage}</p>
              )}

              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md bg-primary-600 px-3 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-primary-600"
                >
                  Send Password Reset Email
                </button>
              </div>

              <div>
                <button
                  type="button"
                  onClick={() => setResetPassword(false)}
                  className="flex w-full justify-center rounded-md bg-gray-500 px-3 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-600"
                >
                  Back to Login
                </button>
              </div>
            </form>
          ) : (
            // Sign In / Sign Up Form
            <form onSubmit={handleSubmit} className="space-y-6">
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-900"
                >
                  Email address
                </label>
                <div className="relative mt-2">
                  <input
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    autoComplete="email"
                    className="bg-white block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm"
                  />
                  <MdEmail className="absolute w-5 h-5 text-gray-900 transform -translate-y-1/2 left-3 top-1/2" />
                </div>
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-900"
                >
                  Password
                </label>
                <div className="relative mt-2">
                  <input
                    type="password"
                    id="password"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                      if (errorMessage) {
                        setErrorMessage(null);
                      }
                    }}
                    required
                    autoComplete="current-password"
                    className="bg-white block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm"
                  />
                  <FaLock className="absolute w-5 h-5 text-gray-900 transform -translate-y-1/2 left-3 top-1/2" />
                </div>
              </div>

              {errorMessage && (
                <p className="text-sm text-red-500">{errorMessage}</p>
              )}

              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <input
                    id="persistSession"
                    name="persistSession"
                    type="checkbox"
                    checked={persistSession}
                    onChange={() => setPersistSession(!persistSession)}
                    className="w-4 h-4 border-gray-300 rounded text-primary-600 focus:ring-primary-600"
                  />
                  <label
                    htmlFor="persistSession"
                    className="block ml-3 text-sm text-gray-900"
                  >
                    Remember me
                  </label>
                </div>
                <div className="text-sm">
                  <button
                    type="button"
                    onClick={() => setResetPassword(true)}
                    className="font-semibold text-primary-600 hover:text-primary-500"
                  >
                    Forgot password?
                  </button>
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md bg-primary-600 px-3 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-primary-600"
                >
                  Sign in
                </button>
              </div>
            </form>
          )}
        </div>

        <p className="mt-10 text-sm text-center text-gray-500">
          By continuing you agree to our{" "}
          <a
            href="/tos"
            className="font-semibold text-primary-600 hover:text-primary-500"
          >
            Terms
          </a>{" "}
          &{" "}
          <a
            href="/privacy-policy"
            className="font-semibold text-primary-600 hover:text-primary-500"
          >
            Privacy Policy
          </a>
        </p>
      </div>
    </div>
  );
};

export default AuthPage;
