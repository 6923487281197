// src/components/Features.js

import React from "react";
import {
  ChartBarIcon,
  ArrowTrendingUpIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";

const features = [
  {
    name: "Measure Marks of Discipleship",
    description:
      "Assess your spiritual strengths and areas for growth through comprehensive self-assessments.",
    href: "/self-assessment",
    icon: ChartBarIcon,
  },
  {
    name: "Visualize Spiritual Growth",
    description:
      "Track your progress over time with personalized graphs and insights to stay motivated.",
    href: "/results",
    icon: ArrowTrendingUpIcon,
  },
  {
    name: "Connect on Your Path to Christ",
    description:
      "Join a community of believers to share experiences and support each other on your spiritual journey.",
    href: "/community",
    icon: UsersIcon,
  },
];

export default function Features() {
  return (
    <div className="py-24 bg-white sm:py-32">
      <div className="px-6 mx-auto max-w-7xl lg:px-8">
        <div className="max-w-2xl mx-auto lg:mx-0">
          <h2 className="text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
            Deepen Your Discipleship Journey
          </h2>
          <p className="mt-6 text-lg text-gray-600">
            Empower your spiritual growth with personalized tools and a
            supportive community.
          </p>
        </div>
        <div className="mt-16 sm:mt-20 lg:mt-24">
          <dl className="grid max-w-xl grid-cols-1 mx-auto gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
            {features.map((feature) => (
              <div key={feature.name} className="flex flex-col">
                <dt className="text-base font-semibold text-gray-900">
                  <div className="flex items-center justify-center w-10 h-10 mb-6 rounded-lg bg-primary-600">
                    <feature.icon
                      aria-hidden="true"
                      className="w-6 h-6 text-white"
                    />
                  </div>
                  {feature.name}
                </dt>
                <dd className="flex flex-col flex-auto mt-1 text-base text-gray-600">
                  <p className="flex-auto">{feature.description}</p>
                  <p className="mt-6">
                    <a
                      href={feature.href}
                      className="text-sm font-semibold text-primary-600 hover:text-primary-500"
                    >
                      Learn more <span aria-hidden="true">→</span>
                    </a>
                  </p>
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
